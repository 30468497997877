<template>
    <div>
        <span v-if="admin && multiple">
            <v-btn
                id="select-all-button"
                @click="selectAll"
                outlined
                color="secondary"
                class="mobile-block mb-2 btn-width"
            >
                <span v-if="printTaxaties.length === filteredTaxaties.filter(filterSearch).length">
                    <v-icon small>mdi-close-box-multiple-outline</v-icon>
                    Deselecteer alle taxaties
                </span>
                <span v-else>
                    <v-icon small>mdi-checkbox-multiple-marked-outline</v-icon>
                    Selecteer alle taxaties
                </span>
            </v-btn>
            <v-btn
                :disabled="printTaxaties.length === 0"
                outlined
                color="secondary"
                class="mobile-block mb-2 ml-6 btn-width"
                @click="viewItem(null)"
            >
                <v-icon small>mdi-file</v-icon>Ga naar geselecteerde taxaties
            </v-btn>
        </span>
        <v-data-table
            :headers="headers"
            :search="search"
            :options="tableOptions"
            :items="filteredTaxaties"
            :item-class="getRowClass"
        >
            <template v-slot:top>
                <v-row>
                    <v-col cols="1">
                        <v-select
                            prepend-icon="mdi-filter"
                            :items="filterItems"
                            item-text="label"
                            item-value="value"
                            v-model="filterValue"
                            label="Filter"
                        />
                    </v-col>
                    <v-col cols="11">
                        <v-text-field
                            id="search-bar"
                            prepend-icon="mdi-magnify"
                            hide-details
                            label="Zoek..."
                            v-model="search"
                            class="mr-4"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-if="admin" #item.start_actions="{ item }">
                <v-row v-if="!multiple">
                    <v-col cols="6" class="taxatie-lijst-data-table-checkbox">
                        <v-checkbox
                            v-model="item.printed"
                            @change="setPrinted($event, item._id)"
                            color="green"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="6" class="taxatie-lijst-data-table-checkbox">
                        <v-checkbox v-model="item.paid" @change="setPaid($event, item._id)" color="orange"></v-checkbox>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12" class="taxatie-lijst-data-table-checkbox">
                        <v-checkbox :value="item._id" v-model="printTaxatiesModel" color="primary" :key="item._id" />
                    </v-col>
                </v-row>
            </template>
            <template #item.chassisnummer="{ item }"> {{ item.chassisnummer || item.kenteken }} </template>
            <template #item.end_actions="{ item }">
                <v-icon
                    name="view-icon"
                    class="mr-2"
                    @click="viewItem(item)"
                    :disabled="item.finished !== undefined && !item.finished"
                    v-if="(item.finished === undefined || item.finished) && (item.temp === undefined || !item.temp)"
                >
                    mdi-file
                </v-icon>
                <span v-if="item.temp" class="caption mr-4">CONCEPT</span>
                <div class="d-inline" v-if="(item.finished === undefined || item.finished) && admin && !multiple">
                    <v-icon name="edit-icon" class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon name="delete-icon" @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </div>
                <v-progress-circular
                    class="ma-auto"
                    v-if="item.finished === false"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </template>
            <template slot="no-data">
                <v-progress-circular
                    :size="200"
                    :width="20"
                    style="margin: 60px;"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import axios from '../../../axios';
import Store from '../../../store';

export default {
    data() {
        return {
            admin: Store.get('admin'),
            printTaxaties: [],
            headers: [
                ...(Store.get('admin')
                    ? [{ text: 'Afvinken', value: 'start_actions', sortable: false, width: 100 }]
                    : []),
                {
                    text: 'Dossiernummer',
                    value: 'dossiernummer',
                    sortable: true
                },
                {
                    text: 'Rapportnummer',
                    value: 'rapportnummer',
                    sortable: true
                },
                {
                    text: 'Auto',
                    value: 'autonaam',
                    sortable: true
                },
                {
                    text: 'Chassisnummer',
                    value: 'chassisnummer',
                    sortable: true
                },
                {
                    text: 'Bedrijfsnaam',
                    value: 'klant',
                    sortable: true
                },
                {
                    text: 'BPM bedrag',
                    value: 'bpmData.rest',
                    sortable: true
                },
                { text: 'Acties', value: 'end_actions', sortable: false, width: 160 }
            ],
            filterValue: '',
            filterItems: [
                {
                    label: 'Alle',
                    value: ''
                },
                {
                    label: 'Niet geprint',
                    value: 'notPrinted'
                },
                {
                    label: 'Geprint',
                    value: 'printed'
                },
                {
                    label: 'Betaald',
                    value: 'paid'
                },
                {
                    label: 'Niet betaald',
                    value: 'notPaid'
                },
                {
                    label: 'Huur auto',
                    value: 'rental'
                }
            ],
            tableOptions: {
                itemsPerPage: 50
            },
            search: ''
        };
    },
    props: {
        taxaties: {
            type: Array,
            requied: true
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        viewItem(item) {
            if (this.multiple) {
                Store.set('printTaxaties', JSON.stringify(this.printTaxaties));
                this.$router.push(`/taxaties/multiple`);
                return;
            }
            this.$router.push(`/taxaties/${item._id}`);
        },
        editItem(item) {
            this.$router.push(`/taxaties/maak/${item._id}`);
        },
        async deleteItem(item) {
            if (confirm(`Wil je rapport ${item.dossiernummer} verwijderen?`)) {
                try {
                    const taxaties = JSON.parse(localStorage.getItem('taxaties'));
                    const taxatie = taxaties.find(taxatie => taxatie._id === item._id);
                    if (taxatie) {
                        taxatie.finished = false;
                    }

                    localStorage.setItem('taxaties', JSON.stringify(taxaties));

                    await axios.delete(`/taxaties/${item._id}`);

                    this.$emit('refresh');
                } catch (error) {
                    console.error(error);
                    alert('Er ging iets fout bij het verwijderen');
                }
            }
        },
        async setPrinted(event, id) {
            await axios.put(`/taxaties/printed/${id}`, { value: event });
        },
        async setPaid(event, id) {
            await axios.put(`/taxaties/paid/${id}`, { value: event });
        },
        getRowClass(item) {
            if (item.paid && item.printed) {
                return 'taxaties-lijst-tabel-list-item-paid-printed';
            } else if (item.paid) {
                return 'taxaties-lijst-tabel-list-item-paid';
            } else if (item.printed) {
                return 'taxaties-lijst-tabel-list-item-printed';
            }
            return '';
        },
        selectAll() {
            if (this.printTaxaties.length === this.taxaties.length) {
                this.printTaxaties = [];
                return;
            }
            if (this.search) {
                this.printTaxaties = this.filteredTaxaties.filter(this.filterSearch).map(taxatie => taxatie._id);
                return;
            }
            this.printTaxaties = this.filteredTaxaties.map(taxatie => taxatie._id);
        },
        filterSearch(taxatie) {
            return Object.keys(taxatie).some(key => {
                if (this.headers.map(header => header.value).includes(key)) {
                    return taxatie[key].toLowerCase().includes(this.search.toLowerCase());
                }
                return false;
            });
        }
    },
    computed: {
        printTaxatiesModel: {
            get() {
                return this.printTaxaties;
            },
            set(value) {
                if (value === null) {
                    return;
                }
                if (Array.isArray(value)) {
                    this.printTaxaties = value;
                    return;
                }
                this.printTaxaties.push(value);
            }
        },
        filteredTaxaties() {
            switch (this.filterValue) {
                case '':
                    return this.taxaties;
                case 'notPrinted':
                    return this.taxaties.filter(taxatie => !taxatie.printed);
                case 'printed':
                    return this.taxaties.filter(taxatie => taxatie.printed);
                case 'paid':
                    return this.taxaties.filter(taxatie => taxatie.paid);
                case 'notPaid':
                    return this.taxaties.filter(taxatie => !taxatie.paid);
                case 'rental':
                    console.log(this.taxaties.filter(taxatie => taxatie.rental === 'ja').map(taxatie => taxatie._id));
                    return this.taxaties.filter(taxatie => taxatie.rental === 'ja');
                default:
                    return this.taxaties;
            }
        }
    }
};
</script>
