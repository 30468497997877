<template>
    <div>
        <v-card class="taxaties-lijst-card">
            <v-card-title>
                Taxaties
            </v-card-title>
            <v-card-subtitle v-if="admin">
                Rapporten deze week: {{ taxatiesThisWeek.length }} <br />
                Rapporten deze maand: {{ taxatiesThisMonth.length }}
            </v-card-subtitle>
            <v-card-actions class="mobile-display-block">
                <v-btn
                    id="select-multiple-button"
                    @click="multipleToggle = !multipleToggle"
                    v-if="admin"
                    outlined
                    color="secondary"
                    class="mobile-block mx-2 btn-width"
                >
                    <v-icon small>mdi-file-document-multiple</v-icon>Selecteer
                    {{ multipleToggle ? 'enkele' : 'meerdere' }} taxaties
                </v-btn>
                <v-btn
                    id="verwijder-ladend-button"
                    @click="clearLoading"
                    v-if="admin"
                    outlined
                    class="mobile-block mx-2 btn-width ml-4"
                    color="red"
                >
                    <v-icon small>mdi-delete</v-icon>
                    Verwijder alle ladende taxaties
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    id="refresh-button"
                    color="secondary"
                    @click="refresh"
                    class="mobile-half-block ma-2"
                    :disabled="isLoading"
                >
                    <v-progress-circular
                        v-if="isLoading"
                        :size="20"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                    <v-icon v-else>
                        mdi-refresh
                    </v-icon>
                </v-btn>
                <v-btn
                    id="maak-taxatie-button"
                    to="/taxaties/maak"
                    v-if="admin"
                    class="mobile-half-block ma-2"
                    color="primary"
                >
                    <v-icon>mdi-plus</v-icon>
                    Maak taxatie
                </v-btn>
            </v-card-actions>
            <v-card-text class="py-2">
                <taxaties-lijst-tabel @refresh="refresh" :taxaties="parsedTaxaties" :multiple="multipleToggle" />
            </v-card-text>
        </v-card>
        <v-alert
            name="fade"
            border="top"
            color="secondary"
            class="rounded-0 text-center taxaties-lijst-bottom-alert white--text"
            v-if="loadingText"
        >
            <v-progress-circular :size="20" indeterminate color="primary"></v-progress-circular> {{ loadingText }}
        </v-alert>
    </div>
</template>

<script>
import { io } from 'socket.io-client';
import axios from '../../../axios';
import Store from '../../../store';
import './TaxatiesLijst.css';
import TaxatiesLijstTabel from './TaxatiesLijstTabel.vue';

const dev = process.env.NODE_ENV === 'development';
const URL = dev ? 'http://localhost:3000' : 'https://j-taxautomotive-rapporten.nl';

export default {
    data() {
        return {
            admin: Store.get('admin'),
            isLoading: false,
            klanten: [],
            parsedTaxaties: [],
            loadAttempts: 0,
            loadingText: '',
            socket: io(URL, {
                autoConnect: true
            }),
            multipleToggle: false
        };
    },
    mounted() {
        this.getTaxaties();

        this.socket.on('new-taxatie', () => {
            this.getTaxaties();
        });
        this.socket.on('error-put-taxatie', () => {
            alert(`
                Fout bij het aanpassen van rapport, oude rapport teruggezet. 
                Probeer het rapport op AutotelexPro aan te maken en probeer het opnieuw.
            `);
            this.getTaxaties();
        });
    },
    computed: {
        taxatiesThisWeek() {
            const dayOfWeek = new Date().getDay();

            // Zondag is 0 dus als zondag zet dan 0 om naar 7
            const days = Array(dayOfWeek > 0 ? dayOfWeek : 7)
                .fill('')
                .map((day, index) => this.getCurDate(index));
            return this.parsedTaxaties.filter(taxatie => days.includes(taxatie.rapportnummer));
        },
        taxatiesThisMonth() {
            const month = new Date().getMonth() + 1;
            const year = new Date().getFullYear();
            return this.parsedTaxaties.filter(
                taxatie =>
                    Number(taxatie.rapportnummer.split('-')[1]) === month &&
                    Number(taxatie.rapportnummer.split('-')[2]) === year
            );
        }
    },
    destroyed() {
        this.socket.disconnect();
    },
    methods: {
        refresh() {
            this.getTaxaties();
        },
        async getTaxaties() {
            this.isLoading = true;

            if (localStorage.getItem('taxaties')) {
                this.klanten = JSON.parse(localStorage.getItem('klanten'));
                this.parsedTaxaties = (JSON.parse(localStorage.getItem('taxaties')) || []).map(this.parseTaxatie);
            }

            this.loadingText = 'Klantgegevens ophalen... (stap 1/3)';
            this.klanten = (await axios.get('/users')).data;

            this.loadingText = 'Laatste 100 taxaties ophalen... (stap 2/3)';
            this.parsedTaxaties = (await axios.get('/taxaties')).data.map(this.parseTaxatie);

            this.loadingText = 'Alle taxaties ophalen... (stap 3/3)';
            this.parsedTaxaties = (await axios.get('/taxaties/all')).data.map(this.parseTaxatie);

            this.saveTaxatiesToLocalStorage();

            this.loadingText = '';

            this.isLoading = false;
        },
        parseTaxatie(taxatie) {
            const klant = this.klanten.find(item => item._id === taxatie.klant);

            return {
                ...taxatie,
                autonaam: parseAutonaam(taxatie),
                klant: klant ? klant.naam : taxatie.klant
            };

            function parseAutonaam(taxatie) {
                if (taxatie.merk && taxatie.type) {
                    return `${taxatie.merk} ${taxatie.type}`;
                } else {
                    return 'LADEN...';
                }
            }
        },
        saveTaxatiesToLocalStorage() {
            localStorage.setItem('klanten', JSON.stringify(this.klanten.slice(0, 500)));
            localStorage.setItem('taxaties', JSON.stringify(this.parsedTaxaties.slice(0, 500)));
        },
        async clearLoading() {
            try {
                await axios.delete('/taxaties/loading');
                this.refresh();
            } catch (error) {
                console.error(error);
            }
        },
        getCurDate(day) {
            const date = new Date();
            return `${date.getDate() - day}-${date.getMonth() + 1}-${date.getFullYear()}`;
        }
    },
    components: {
        TaxatiesLijstTabel
    },
    metaInfo: {
        title: 'Taxatie lijst'
    }
};
</script>

<style scoped>
.taxaties-lijst-bottom-alert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    margin: 0;
}

.taxatie-lijst-data-table-checkbox {
    padding: 0;
    display: flex;
    justify-content: center;
}

.taxaties-lijst-card {
    margin-bottom: 80px;
}
</style>
